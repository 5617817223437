/* Target the about page */
.about-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Target the title background container */
.about-title-background-container{
    display: flex;
    width: 90%;
    background-color: var(--color-supporting-black);
    border: 1px solid rgb(63, 63, 63);
    border-radius: 2rem;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Target the title container */
.about-title-container{
    display: flex;
    padding: 50px;
}

/* Target the image container */
.about-image-container{
    display: flex;
    position: relative;
    width: 25%;
    height: 100%;
    min-width: 250px;
    min-height: 333px;
    box-shadow: -20px 20px 0px var(--color-dark-gold);
    opacity: 0;
    animation: opacity-fade-in 2s cubic-bezier(0.42, 0, 0.58, 1) 0.25s forwards;
    aspect-ratio: 3 / 4;
}

/* Target the image in image container */
.about-image-container > img{
    z-index: 2;
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
}

/* Target text container */
.about-text-container{
    width: 75%;
    height: 100%;
    max-height: max(250px * 1.33, calc(80vw * 0.3) * 1.33);
    margin-left: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: rgb(255, 255, 255);
    scrollbar-width: 2px;
    padding-right: 5px;
}

/* Target scroll bar */
.about-text-container::-webkit-scrollbar{
    background-color: rgb(255, 255, 255);
    width: 2px;
}

/* Target all p childs in text container */
.about-text-container > p{
    color: rgb(200, 200, 200);
    font-size: max(1rem, 1.75vw);
    opacity: 0;
}

/* Target third child */
.about-text-container > :nth-child(3){
    animation: fade-in-left 1s 0.25s ease-out forwards;
}

/* Target 5th child */
.about-text-container > :nth-child(5){
    animation: fade-in-right-50 1s 0.5s ease-out forwards;
}

/* Target arrow down icon */
.about-folder-arrow{
    width: 30px;
    height: 30px;
    color: var(--color-dark-gold);
    padding-right: 5px;
}

/* Target first child in text container */
.about-text-container > :first-child{
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    animation: fade-in-right-50 1s 0.15s forwards;
}

/* Target the first child after element in text container */
.about-text-container > :first-child::after{
    content: " ";
    background-color: gold;
    margin-left: 10px;
    width: 50%;
    max-width: 200px;
    height: 3px;
    border-radius: 3rem;
}

/* Target the about resume container */
.about-resume-container{
    height: fit-content;
    display: flex;
    align-items: center;
    padding: 50px 0 10px 0;
    opacity: 0;
}

/* 660px */
@media (max-width: 660px){
    .about-title-container{
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .about-image-container{
        min-height: fit-content;
        height: calc(64vw * 1.33);
    }

    .about-image-container{
        width: 80%;
    }

    .about-text-container{
        margin-top: 50px;
        width: 100%;
        max-height: fit-content;
        margin-left: 0;
        overflow-y: visible;
        padding: 0;
    }

    .about-resume-container{
        padding: 40px 0 40px 0;
    }
}


