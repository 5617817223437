@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

:root{
    --card-width: 500px;
    --card-height: 340px;
    --card-margin: 20px;
}

/* Target portfolio container */
.portfolio-container{
    display: flex;
    height: calc(100% - 70px - 30px);
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    align-items: center;
    flex-direction: column;
}

/* Target the title container */
.portfolio-title{
    margin: 50px 0 30px 0;
}

/* Target all p elements in the title container */
.portfolio-title > p{
    text-align: center;
}

/* Target the second element */
.portfolio-title > :nth-child(2){
    margin-top: 10px;
    font-size: 1.1rem;
    background: linear-gradient(to right, rgb(255, 255, 255) 50%, rgb(255, 210, 0));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Target all p elements in the wave text container */
.wave-text > p{
    font-family: "Poppins", sans-serif;
    color: #fff;
	font-size: 3em;
	position: absolute;
	transform: translate(-50%, -50%);
}

/* Target wave text container */
.wave-text {
	position: relative;
    left: 50%;
    height: 30px;
}

/* Target first element */
.wave-text p:nth-child(1) {
	color: transparent;
	-webkit-text-stroke: 2px rgb(255, 255, 255);
}

/* Target second element */
.wave-text p:nth-child(2) {
	color: rgb(255, 255, 255);
	animation: wave-animate 4s ease-in-out infinite;
}

/* Target the portfolio grid container */
.portfolio-grid{
    display: grid;
    width: 80%;
    grid-gap: 5px 5px;
    grid-template-columns: repeat(auto-fill, calc(var(--card-width) + calc(var(--card-margin) * 2)));
    justify-content: center;
}

/* Target all odd childs */
.portfolio-grid > :nth-child(odd){
    opacity: 0;
    animation: portfolio-fade-in-down 1.75s 0.25s forwards;
}

/* Target all even childs */
.portfolio-grid > :nth-child(even){
    opacity: 0;
    animation: portfolio-fade-in-up 1.75s 0.25s forwards;
}

/* Target all card containers */
.card-container{
    width: var(--card-width);
    height: var(--card-height);
    margin: var(--card-margin);
    background-color: var(--color-supporting-black);
    border: 1px solid rgb(63, 63, 63);
    border-radius: 1rem;
    box-shadow: 7px 7px 10px black;
    transition: all 0.2s ease-in-out;
    border: 1px solid black;
}

/* Target card container on hover */
.card-container:hover{
    background-color: rgb(70,70,70);
    transform: translateY(-10px);
    cursor: pointer;
}

/* Target all card images */
.card-image{
    width: 100%;
    height: 60%;
}

/* Target the image element of the card image container */
.card-image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.75rem;
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(70, 70, 70, 1));
}

/* Target card title container */
.card-title{
    display: flex;
    margin: 20px 0 20px 0;
}

/* Target all p childs in card title container */
.card-title > p{
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
}

/* Target the card description */
.card-description{
    margin-bottom: 20px;
}

/* Target all p childs in card description container */
.card-description > p{
    color: rgb(225, 225, 225);
    font-size: 1.2rem;
}

/* Target the card tools container */
.card-tools-container{
    display: flex;
}

/* Target all p elements in the card tools container */
.card-tools-container > p{
    color: rgb(180, 180, 180);
}

/* Target the bullet point between tools */
.card-tool-with-bullet::after{
    content: "•";
    color: white;
    padding: 0 5px 0 5px;
}

/* Target link container */
.link-container{
    display: flex;
    justify-content: flex-end;
}

.link-container > a{
    opacity: 0.5;
}

.link-container > a:hover{
    opacity: 1;
}

/* Target the image element within the a tag within link container */
.link-container > a > img{
    width: 25px;
    height: 25px;
}

.card-translate-container{
    height: 40%;
    transform: translateY(-50px);
    padding: 0 10px 0 10px;
}

/* 550px */
@media (max-width: 550px){
    :root{
        --card-width: 440px;
    }
}

/* 480px */
@media (max-width: 480px){
    :root{
        --card-width: 370px;
        --card-height: 400px;
    }
}

/* 400px */
@media (max-width: 400px){
    :root{
        --card-width: 320px;
        --card-height: 400px;
    }
}