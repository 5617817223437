/* Target project explorer container */
.project-explorer{
    margin-top: 2px;
    display: flex;
    width: 100%;
    min-height: 30px;
    height: 30px;
    background-color: rgb(50, 50, 50);
    box-shadow: 0 2px 1px #1e1e1e;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

/* Target project explorer animation */
.project-explorer-animation{
    animation: fade-in-right-1000 1.75s 0.25s ease-in forwards;
}

/* Target project explorer scrollbar */
.project-explorer::-webkit-scrollbar { 
    display: none;  /* Chrome and safari */
}

/* Target project explorer items */
.project-explorer-nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    opacity: 0.8;
    
}

/* Target project explorer item on hover*/
.project-explorer-nav:hover{
    cursor: pointer;
    opacity: 1;
}

/* Target project explorer item p elements */
.project-explorer-nav > p{
    color: rgb(225,225,225);
    padding-right: 3px;
    text-wrap: nowrap;
}

/* Target project explorer selected item*/
.project-explorer-selected{
    background-color: var(--color-opaque-navy-blue);
    opacity: 1;
}

/* Target project explorer item div elements */
.project-explorer-nav > div{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Target project explorer close icon */
.project-explorer-nav-x-outline{
    width: 20px;
    height: 20px;
    color: white;
}

/* Target project explorer close icon on hover */
.project-explorer-nav-x-outline:hover{
    width: 20px;
    height: 20px;
    fill: white;
    color: rgba(0,0,0,1);
}

/* 660px */
@media (max-width: 660px){
    .project-explorer-animation{
    animation: fade-in-right-250 1.5s 0s ease-in forwards;
    }
}