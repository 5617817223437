/* Navbar */
.navbar{
    display: flex;
    width: 100%;
    height: 50px;
    background-color: var(--color-main-black);
    box-shadow: 0 2px 1px #1e1e1e;
    padding: 10px 5px 10px 5px;
}

/* Navbar Container */
.navbar-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.navbar-container > :first-child, .navbar-container > :last-child{
    transform: translateY(-200px);
    animation: slide-down-intro 1s forwards;
}

/* Target all p elements and change font */
.navbar-container p{
    font-family: 'Montserrat',sans-serif;
}

/* Target all p elements on hover and change cursor */
.navbar-container p:hover{
    cursor: pointer;
}

/* Target all div elements and set them to flex-box and center their elements */
.navbar-container div{
    display: flex;
    align-items: center;
}

/* Target the unordered list that holds the links */
.navbar-container > ul{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 40%;
    justify-content: space-evenly;
}

/* Target the list elements */
.navbar-container > ul > li{
    display: flex;
    align-items: center;
    list-style: none;
    padding: 5px;
}

.navbar-container > ul > li:nth-child(1){
    opacity: 0;
    animation: fade-in-down 2s forwards;
}

/* Delay the second link by 0.25s */
.navbar-container > ul > li:nth-child(2){
    opacity: 0;
    animation: fade-in-down 2s 0.25s forwards;
}

/* Delay the third link by 0.5s */
.navbar-container > ul > li:nth-child(3){
    opacity: 0;
    animation: fade-in-down 2s 0.5s forwards;
}

/* Set the home link color to white and increase size */
.navbar-container > div:first-child > p{
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
}

/* Target all p elements in the links list */
.navbar-container > ul > li > p{
    position: relative;
    color: white;
    opacity: 0.5;
    font-size: 1.25rem;
}

/* When the link is active, set its opacity to 1 */
.navbar-container > ul > li > .active{
    opacity: 1;
}

/* When the link is not active, but being hovered over, set it's opacity to 0.8 */
.navbar-container > ul > li > p:not(.active):hover{
    opacity: 0.8;
}

/* Underline under the currently active link */
.navbar-container > ul > li > .active::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: var(--color-light-gold);
    border-radius: 3rem;
    bottom: -5px;
    left: 0;
}

/* Target logo div container */
.navbar-logo-container{
    padding: 3px;
}

/* Target logo images */
.logos > a > img{
    display: flex;
    padding-right: 10px;
    opacity: 0.8;
}

/* Target logo images on hover */
.logos > a > img:hover{
    opacity: 1;
}

/* Target title container */
.navbar-home-elements-container{
    transition: all ease-in-out 1s !important;
}

/* Target title p tag */
.navbar-home-elements-container > p {
    transition: all 1s ease !important;
    transform-origin: center;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, rgb(255, 255, 255) 50%, var(--color-light-gold) 99%);
    background-size: 200%;
    background-position: left center;
    opacity: 0.8;
}

/* Smooth gradient effect on hover */
.navbar-home-elements-container > p:hover {
    background-position: right center;
    opacity: 1;
}


/* Target 610px */
@media (max-width: 610px){
    .navbar-container > ul > li > p{
        font-size: 0.75rem;
    }
    .navbar-container > div:first-child > p{
        font-size: 1rem;
    }
}

/* Target 250px */
@media (max-width: 250px){
    .navbar-container > ul > li > p{
        font-size: 0.6rem;
    }
    .navbar-container > div:first-child > p{
        font-size: 0.9rem;
    }
}